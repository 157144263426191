h6.clustor-title {
  color: var(--White, #fff);
  font-family: "Playfair Display" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.6;
}

ul.clustor-list {
  padding: 0px !important;
}
.rectangle-8 {
  transition: 0.3s;
}
/* .rectangle-8:hover {
  transform: scale(1.1);
} */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .clustor-boxes-section-main {
    margin-top: 3rem !important;
  }
}
