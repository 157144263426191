.aminities-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: space-around;
  padding-bottom: 30px;
}

element.style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.card-section {
  border-radius: 16px;
  background: #313b2f;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
  color: white;
  margin: 7px;
}

.card-section {
  border-radius: 16px;
  background: #313b2f;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
  color: white;
  /* margin: 7px 19px; */
  padding: 32px;
  text-align: left !important;
}

h4.card-title {
  text-align: center;
  color: var(--White, #fff);
  font-family: "Playfair Display" !important
  ;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.card-aminities {
  display: flex;
  align-content: center;
  align-items: center;
}

.card-aminities {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 26px;
  width: 11rem;
}

.card-aminities p {
  padding-left: 2rem;
}

.card-section {
  border-radius: 16px;
  background: #313b2f;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
  color: white;
  /* margin: 7px 14px; */
  padding: 33px;
  text-align: left !important;
  height: 538px;
}

.aminities-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: space-around;
  padding-bottom: 30px;
  margin-left: -7px;
}

.card-aminities img {
  width: 40px;
  height: 40px;
}

.card-aminities p {
  padding-left: 2rem;
  color: white;
}
.four-box {
  border-radius: 16px;
  background: rgba(49, 59, 47, 0.7) !important;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32) !important;
}

.five-box {
  border-radius: 16px;
  background: rgba(49, 59, 47, 0.6) !important;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32) !important;
}

.third-box {
  border-radius: 16px;
  background: rgba(49, 59, 47, 0.8) !important;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32) !important;
}
.secound-box {
  border-radius: 16px;
  background: rgba(49, 59, 47, 0.9) !important;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32) !important;
}
