.gallery-container {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .gallery-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .gallery-button:hover {
    color: #CBC5B3;
    ;
  }
  
  .gallery-button:first-child {
    left: 10px;
  }
  
  .gallery-button:last-child {
    right: 10px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }