.css-1imhtct-MuiTypography-root {
  line-height: 2.5 !important;
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 18px;
}
.css-5cqd15-MuiTypography-root {
  font-family: "Helvetica Neue", sans-serif !important;
}
.css-l7qz1a-MuiGrid-root {
  align-items: baseline !important;
}

.css-o4bg9w-MuiGrid-root {
  -webkit-flex-basis: 66.666667%;
  -ms-flex-preferred-size: 66.666667%;
  flex-basis: 124.666667% !important;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 101.666667% !important;
}

.icon-list {
  display: flex;
  justify-content: flex-start;
}
img.icon-list-img {
  margin-right: 30px;
  width: 50px;
}

span.mins-text {
  color: var(--Primary, #313b2f);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.icon-list-title {
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 20px;
  font-style: normal !important;
  font-weight: 300;
  line-height: 33px !important;
}

.icon-list-heading {
  color: var(--Black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.6;
  font-family: "Helvetica Neue", sans-serif !important;
}

.icon-list {
  margin-bottom: 2rem;
}

.location-options {
  flex-basis: 100.333333% !important;

  max-width: 100.333333% !important;
}
