footer {
  background: var(--Secondary, #cbc5b3);
}

.main-container-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

ul.footer-menu {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0px;
  list-style: none;
}

.footer-menu li a {
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}
/* .social-links-row ul {
  list-style: none;
} */

img.footer-logo {
  width: auto;
}

.social-links-row ul {
  list-style: none;
  display: flex;
  gap: 31px;
  justify-content: flex-end;
}

.social-links-item a {
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none !important;
}

.main-container-footer {
  align-items: center;
}

img.socail-icons-footer {
  padding-left: 20px;
}

.copyright-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.copyright-row {
  padding-top: 6px;
  border-top: 2px solid #313b2f;
  margin-top: 2rem;
}

@media only screen and (min-width: 250px) and (max-width: 1199px) {
  .terms-row {
    display: flex;
    justify-content: space-around;
    padding: 0px 33px;
    flex-direction: column;
  }
  .copyright-asha {
    text-align: center;
  }
  .terms-row {
    text-align: center;
  }
  .utc-text {
    text-align: center;
  }
  .main-container-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }

  .copyright-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }

  .social-links-row ul {
    list-style: none;
    display: flex;
    gap: 31px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0;
  }

  .social-links-item a {
    color: var(--Black, #000);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration: none;
  }

  footer {
    padding: 0px 12px !important;
  }
}

.terms-row {
  display: flex;
  justify-content: space-around;
  padding: 0px 33px;
}
.terms-row p {
  padding: 0px 33px;
}

