.sub-title {
  color: var(--Black, #000);
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Feature-container {
  padding-bottom: 50px;
}

.title {
  color: #313b2f !important;
  font-family: "Playfair Display", sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
}

.sub-title {
  color: #000 !important;
  font-family: "Playfair Display", sans-serif !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.body-sub {
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.secound-bg-estate {
  border-radius: 16px;
  background: rgba(203, 197, 179, 0.4) !important;
}

.third-bg-estate {
  border-radius: 16px;
  background: rgba(203, 197, 179, 0.4) !important;
}
