/* .homehero-section-root {
  background-image: url("../../assets/homeHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  position: relative;
  
} */

/* .homehero-section-root {
  background-image: url("../../assets/homeHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  filter: brightness(80%);
  height: 100vh;
}

.homehero-section-root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/homeHero.jpg");
  background-size: cover;
  background-position: center;
  filter: brightness(80%);
  z-index: -1;
} */

.homehero-section-root {
  background-color: transparent;
  /* background-color: red; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* filter: brightness(70%); */

  position: relative;
  height: 100vh;
}

.homehero-section-root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/assets/Picture1-1-1.png");
  background-size: cover;
  background-position: center;
  filter: brightness(60%);
  z-index: -1;
}

.homehero-section-root > * {
  position: relative;
  z-index: 1;
}

.homehero-text-div {
  /* padding-top: 320px;
  padding-bottom: 150px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  opacity: 1;
  /* z-index: 999; */
  height: 100vh;
}

.main-upper-laya-heading {
  font-family: sectionTitleFont;
  font-size: 62px;
  /* font-weight: 300; */
  /* text-transform: uppercase; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  margin-bottom: 0 !important;
  letter-spacing: 2px;
  text-align: center;
}

.avenir {
  font-family: Avenir;
}

.main-upper-heading {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 6px !important;
  /* margin-bottom: 100px; */
  letter-spacing: 1px;
  text-transform: uppercase;
}

.main-home-hero-heading {
  text-align: center;
  font-family: Avenir;
  font-size: 38px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 6px;
  text-transform: uppercase;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.learnMore-button {
  background: none;
  font-family: Avenir;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid #fff !important;
  color: #fff;
  padding: 13px 23px;
  /* width: 200px; */
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  transition: all 1s ease;
}

.font-play-fair{
      font-family: "Playfair Display" !important;
}

.line-svg {
  position: absolute;
  top: 13px;
  left: 150px;
}

.learnMore-button:hover {
  background-color: #fff;
  color: #000;
  transition: all 1s ease;
  /* margin-right: 40px; */
}

.popup-logo {
  max-width: 160px;
  width: 100%;
  color: white;
  /* Text color */
  -webkit-text-stroke: 1px black;
  /* Outline size and color */
}

.modal-popup-root {
  color: #000;
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  position: relative;
}

.modal-heading-border-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.modal-small-heading-border {
  border: 1px solid #000;
  max-width: 150px;
  width: 100%;
  margin-left: 6px;
}

.modal-popup-download-heading {
  margin: 0;
  font-family: Avenir;
  font-size: 14px !important;
  font-weight: 600;
  color: #b37f2b !important;
  letter-spacing: 3.5px !important;
  text-transform: uppercase;
}

.modal-popup-heading-text {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 400;
  max-width: 400px;
}

.modal-popup-input-fields-div {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  padding: 30px 20px;
  gap: 20px;
  max-width: 400px;
  text-align: center;
  width: 100% !important;
  /* align-items: center; */
}

.close-icon-popup {
  cursor: pointer;
  position: absolute;
  right: -38px;
  top: -30px;
  z-index: 99999;
}

.modal-fill-form-heading {
  margin: 0;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  /* margin-left: 15px !important; */
}

.modal-input-field {
  background: none;
  border: 1px solid #000;
  padding: 8px;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
}

.popup-modal-send-btn {
  background: none;
  border: 1px solid #000;
  color: #000;
  width: 140px;
  padding: 10px 0;
  cursor: pointer;
  transition: all 2s ease;
  margin: 0 auto;
}

.popup-modal-send-btn:hover {
  background-color: #000;
  color: #fff;
  transition: all 2s ease;
}

input:focus {
  outline: none;
}

.brochure-img {
  max-width: 850px;
  width: 100%;
  position: absolute;
  top: 0px;
  right: -90px;
  transform: scale(1.2);
  filter: saturate(1.8);
}

.submit-text {
  font-family: Avenir;
  font-size: 12px !important;
  font-weight: 400;
  margin: 0;
}

.download-btn {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  color: #000 !important;
}

@media screen and (max-width: 750px) {
  .main-upper-heading {
    font-size: 16px;
  }

  .main-home-hero-heading {
    font-size: 18px;
    letter-spacing: 2px;
  }

  .main-upper-laya-heading {
    font-size: 38px;
  }

  .modal-popup-root {
    flex-direction: column;
    height: 780px;
  }

  .modal-popup-input-fields-div {
    padding: 30px 40px;
    align-items: center;
    max-width: 258px;
  }

  .modal-input-field {
    max-width: 238px;
  }

  .modal-popup-logo-text-div {
    text-align: center;
  }

  .modal-fill-form-heading {
    margin-left: 0 !important;
  }

  .popup-modal-send-btn {
    margin-left: 0 !important;
  }

  .brochure-img {
    right: 0%;
    top: 566px;
  }

  .close-icon-popup {
    right: -18px;
    top: -10px;
  }
}

.payment-plan-main-root-div {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
}

.payment-plan-sub-root-div {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

.payment-plan-small-heading-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-plan-small-heading {
  margin: 0;
  font-family: Avenir;
  font-size: 14px !important;
  font-weight: 600;
  color: #b37f2b !important;
  letter-spacing: 3.5px !important;
  text-transform: uppercase;
}

.payment-plan-small-heading-border {
  border: 1px solid #d6d6d6;
  max-width: 150px;
  width: 100%;
  margin-left: 6px;
}

.payment-plan-main-heading {
  font-family: Avenir;
  font-size: 50px;
  font-weight: 400;
  margin: 0;
  margin-top: 10px;
}

.payment-plan-text {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 1px;
}

.payment-plan-img-and-plan {
  display: flex;
  margin-top: 30px;
}

.payment-plan-img {
  width: 100%;
}

.payment-plan-bar-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #28282b;
  width: 500px;
  color: #fff;
  padding: 0 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.progress-circle {
  width: 160px;
  fill: #7e283b !important;
  stroke: #7e283b !important;
}

.circle-progress-div {
  text-align: center;
}

.payment-plan-bars {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #7e283b !important;
}

.payment-plan-progress-text {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
}

.timeline-dot {
  width: 50px;
  justify-content: center;
  background: #b37f2b !important;
}

.timeline-content-section-r {
  background-color: #f6f6f6;
  padding: 15px 25px;
  border-radius: 10px;
  border: 1px solid #b37f2b;
}

.timeline-content-section-r::after {
  content: " ";
  position: absolute;
  bottom: 23px;
  left: -23px;
  margin-left: -5px;
  border-width: 14px;
  border-style: solid;
  border-color: transparent transparent #b37f2b transparent;
  transform: rotate(-90deg);
}

.timeline-content-section-l {
  background-color: #f6f6f6;
  padding: 15px 25px;
  border-radius: 10px;
  border: 1px solid #b37f2b;
}

.timeline-content-section-l::after {
  content: " ";
  position: absolute;
  bottom: 23px;
  right: -28px;
  margin-left: -5px;
  border-width: 14px;
  border-style: solid;
  border-color: transparent transparent #b37f2b transparent;
  transform: rotate(90deg);
}

.timeline-dot-text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
}

.timeline-box-text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.installment-text {
  color: #b37f2b;
  font-weight: 600 !important;
}

@media screen and (max-width: 750px) {
  .timeline-item::before {
    display: none !important;
  }

  .payment-plan-main-heading-div {
    padding: 0 15px;
  }

  .payment-plan-main-heading {
    font-size: 40px;
  }

  .payment-plan-text {
    padding: 0 15px;
    font-size: 16px;
    line-height: 20px;
  }

  .timeline-content-section-r::after {
    left: 102%;
    transform: rotate(90deg);
  }
}
