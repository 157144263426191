body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MontserratRegular";
  src: url("../public/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("../public/fonts/HelveticaNeue.ttf") format("truetype");
}
@font-face {
  font-family: "TajawalRegular";
  src: url("../public/fonts/Tajawal-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PlayfairDisplay";
  src: url("../public/fonts/PlayfairDisplay.ttf") format("truetype");
}

@font-face {
  font-family: "MBFRoyalRegular";
  src: url("../public/fonts/MBF Royal Regular.otf") format("opentype");
}

@font-face {
  font-family: "Colabero";
  src: url("../public/fonts/Colabero.ttf") format("truetype");
}

.textShadow{
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

}
p#contactsection {
  color: #000;
  font-family: "Playfair Display" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

element.style {
  font-family: sectionTitleFont;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.title-video-container.css-1qokjtz {
  font-family: sectionTitleFont;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 62px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 83px;
  z-index: 40 !important;
}

.SubTitle-video-container.css-1lhfysi {
  padding-top: 92px;
  color: var(--White, #fff);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  top: 53%;
  z-index: 40 !important;
}

.broucher-btn {
  margin-top: 2rem !important;
  border: 1px solid var(--White, #fff) !important;
  background-color: transparent !important;
  color: white !important;
  padding: 16px 32px !important;
  color: var(--White, #fff);
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  top: 73% !important;
  z-index: 40 !important;
}

p {
  color: #000;
  font-family: "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.broucher-btn:hover {
  background-color: white !important;
  color: #000 !important;
  border: none !important;
}

.main-menu button:hover {
  background-color: transparent;
}

.main-menu button {
  align-items: center !important;
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.main-menu-container {
  /* background: #cbc5b3; */
  /* box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08); */
}

/* Styles for screens smaller than 600px (typically mobile devices) */
@media only screen and (max-width: 900px) {
  ul.footer-menu {
    display: none !important;
  }
  .logo.css-6w6kfw {
    z-index: 5 !important;
  }
  .contact-row-ipad {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .css-o4bg9w-MuiGrid-root {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100.666667% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.666667% !important;
  }
  .about-grid-mobile {
    padding-left: 1px !important;
  }

  .css-1ild15j-MuiGrid-root {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between !important;
    justify-content: center !important;
    -webkit-align-items: center;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    align-content: center !important;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-direction-xs-column.Content-bullets.css-h7pwju-MuiGrid-root {
    padding-top: 0px !important;
  }

  .css-qi3ouq-MuiGrid-root {
    padding-top: 25px !important;
  }

  .discription-pera {
    text-align: justify !important;
  }

  .discription-pera-main {
    text-align: justify !important;
  }

  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    padding-left: 0rem !important;
  }

  img.rectangle-10 {
    max-width: 300px !important;
  }

  .features-container {
    padding-right: 20px;
    padding: 30px 0px 0px 0px !important;
  }

  .location-options {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-content: flex-start !important;
    justify-content: center !important;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-direction-xs-column.Content-bullets.css-h7pwju-MuiGrid-root {
    padding-top: 122px;
  }

  img.partners-logo {
    width: 150px;
    margin-bottom: 25px !important;
  }

  div#root {
    overflow: hidden !important;
  }
  .about-grid-mobile {
    margin-top: -18rem !important;
  }
  img.logo-devloper-ah {
    width: 100% !important;
    margin-top: 0rem !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    display: inline-flex !important;
    align-content: center !important;
    margin-left: 0rem !important;
  }

  .discription-pera {
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-bottom: 20px !important;
    opacity: 0.6;
    color: black;
    margin-bottom: 40px;
  }
  .contact-text-container-2 {
    display: block;
    width: 410px;
    margin-left: 20px !important;
  }

  .card-section {
    width: 80% !important;
    margin-left: 0px !important;
  }

  .box-clusters {
    text-align: center !important;
    border-radius: 8px !important;
    border: 1px solid rgb(203, 197, 179) !important;
    height: 100px !important;
    width: 270px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
  }

  .last-box {
    margin-top: 4rem !important;
  }

  .bg-image-col {
    max-width: 106.333333% !important;
    padding-top: 496px !important;
    margin: auto !important;
    width: 100% !important;
    /* height: 100% !important; */
    margin-top: 2rem !important;
    margin-left: 0rem !important;
  }

  .css-zqi6k4-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 83.333333%;
    -ms-flex-preferred-size: 83.333333%;
    flex-basis: 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.333333% !important;
  }
  .box-2 {
    /* height: 150px !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    margin-top: 1rem;
    height: 113px !important;
    margin-top: 1rem;
    width: 278px !important;
    padding-left: 20px !important;
    text-align: left !important;
  }

  img.gallery-control-btn {
    width: 30px !important;
  }
  .gallery-image {
    width: 100% !important;
    height: 426px !important;
    display: block !important;
    margin: 0 auto !important;
    object-fit: cover !important;
  }
  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 0px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  .box-1 {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    margin-top: 1rem;
    height: 100px !important;
    margin-top: 1rem;

    width: 286px !important;
    text-align: left !important;
  }

  .grid-mobile-aboutus {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-around !important;
    flex-wrap: nowrap !important;
    align-items: center;
  }
  .partners-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 57px;
    flex-direction: column !important;
    display: block !important;
    padding: 0px !important;
  }

  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: center !important;
    padding-left: 0px !important;
    font-weight: 600 !important;
  }

  ul.clustor-list li {
    display: flex !important;
    align-content: center !important;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    align-items: center !important;
    padding-bottom: 26px !important;
  }
  .title-video-container.css-1qokjtz {
    font-family: sectionTitleFont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 41px !important;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 70px;
    z-index: 40 !important;
  }

  .css-6w6kfw {
    position: absolute;
    top: 10% !important;
    /* left: 50% !important; */
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-weight: bold;
  }

  .request-btn {
    border: 1px solid #fff !important;
    background-color: transparent !important;
    color: white !important;
    padding: 16px 32px !important;
    color: var(--White, #fff);
    font-family: "Helvetica Neue" !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal !important;
    text-transform: uppercase !important;
    display: none !important;
    z-index: 9999 !important;
  }

  .partners-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 57px;
    flex-direction: column !important;
  }

  .aminities-container {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-around !important;
    align-content: space-around;
    padding-bottom: 30px !important;
    margin-left: 0px !important;
    flex-direction: column !important;
  }

  .card-section {
    border-radius: 16px;
    background: #313b2f;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
    color: white;
    /* margin: 7px 14px; */
    /* text-align: left !important; */
    height: 538px;
    width: 60%;
  }

  .SubTitle-video-container.css-1lhfysi {
    padding-top: 92px;
    color: var(--White, #fff);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    top: 57%;
    z-index: 40 !important;
  }

    .SubTitle-logo-container.css-1j2eq1b {
    top: 62% !important;
  }

  div#root body {
    overflow-x: hidden;
  }

  .card-features {
    padding-bottom: 36px;
  }

  .partners-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 57px;
    flex-direction: column !important;
  }

  p.partners-para {
    padding-left: 0px;
    text-align: center;
  }

  img.partners-logo {
    width: 171px;
  }
}

@media only screen and (max-width: 599px) {
  /* Your CSS styles for mobile devices */
  .features-container {
    width: 100% !important;
    height: auto !important;
    background-color: rgb(203, 197, 179) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 40px 0px 0px 10px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 8px !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    align-content: flex-start !important;
  }
}

/* Styles for screens between 600px and 959px (typically larger smartphones and smaller tablets) */
/* web screen media start */
@media only screen and (min-width: 1500px) and (max-width: 1600px) {
  /*.MuiGrid-container {
      padding-left: 50px !important;
      padding-right: 50px !important;
  }*/
  footer .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 300px;
  }
  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    padding-left: 2rem !important;
  }

  .card-section {
    border-radius: 16px;
    background: #313b2f;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
    color: white;
    /* margin: 7px 14px; */
    padding: 32px !important;
    text-align: left !important;
    height: 538px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .features-container.aos-init.aos-animate {
    width: 86vw !important;
    padding: 0px 27px !important;
  }

  .about {
    width: 100% !important;
  }

  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    padding-left: 2rem !important;
  }
  footer .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 300px;
  }
  .box-2 {
    /* height: 150px !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start;
    margin-top: 1rem;
    height: 100px !important;
    margin-top: 1rem;
    width: 390px !important;
  }

  .box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100px !important;
    margin-top: 1rem;
    width: 173px !important;
    align-items: flex-start !important;
  }

  .card-section {
    border-radius: 16px;
    background: #313b2f;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
    color: white;
    /* margin: 7px 14px; */
    padding: 32px !important;
    text-align: left !important;
    height: 538px;
  }
}

@media only screen and (min-width: 1200px) {
  .css-6w6kfw {
    position: absolute;
    top: 10% !important;
    left: 10% !important;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-weight: bold;
    z-index: 10000;
  }

  .request-btn-m {
    background-color: transparent !important;
    top: 14% !important;
    right: 65px;
    box-shadow: none !important;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 1920px) {
  img.rectangle-10 {
    max-width: 90% !important;
  }

  footer .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 300px;
  }
  .location-options {
    margin-top: 130px !important;
  }
  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    margin-left: 2rem !important;
  }

  .box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 113px !important;
    margin-top: 1rem;
    width: 230px !important;
    align-items: flex-start !important;
  }

  .card-section {
    border-radius: 16px;
    background: #313b2f;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
    color: white;
    /* margin: 7px 14px; */
    padding: 73px !important;
    text-align: left !important;
    height: 538px;
  }

  .box-2 {
    /* height: 150px !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start;
    margin-top: 1rem;
    height: 113px !important;
    margin-top: 1rem;
    width: 506px !important;
    align-items: flex-start !important;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1600px) {
  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    margin-left: 2rem !important;
  }

  .box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 113px !important;
    margin-top: 1rem;
    width: 197px !important;
    align-items: flex-start !important;
  }

  .card-section {
    border-radius: 16px;
    background: #313b2f;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.32);
    color: white;
    /* margin: 7px 14px; */
    padding: 32px !important;
    text-align: left !important;
    height: 538px;
  }
  .box-2 {
    /* height: 150px !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start;
    margin-top: 1rem;
    height: 113px !important;
    margin-top: 1rem;
    width: 430px !important;
    align-items: flex-start !important;
  }
  .about-sub-box {
    font-size: 15px !important;
  }
  .location-options {
    margin-top: 130px !important;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1366px) {
  .location-options {
    margin-top: 130px !important;
  }
  footer .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 300px;
  }
  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    margin-left: 2rem !important;
  }

  .estate {
    padding: 0px !important;
    color: black !important;
    opacity: 1 !important;
    text-align: left;
    padding-left: 50px;
    font-weight: 600 !important;
    padding-left: 2rem !important;
  }

  .main-menu button {
    align-items: center !important;
    color: var(--Black, #000);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-right: 0rem !important;
  }

  .box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 113px !important;
    margin-top: 1rem;
    width: 160px !important;
  }

  .box-2 {
    /* height: 150px !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start;
    margin-top: 1rem;
    height: 113px !important;
    margin-top: 1rem;
    width: 342px !important;
  }
}
/*  */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  /* Your CSS styles for larger smartphones and smaller tablets */

  .title-video-container.css-1qokjtz {
    font-family: sectionTitleFont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 41px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 83px;
    z-index: 40 !important;
  }
}

/* Styles for screens between 960px and 1279px (typically larger tablets and small laptops) */

@media only screen and (min-width: 250px) and (max-width: 390px) {
  .boxs-border-cards {
    display: flex;
    gap: 44px;
    flex-direction: column;
    width: 260px !important;
    align-items: flex-start;
    padding-left: 29px;
    max-width: 100%;
  }

  /* Your CSS styles for larger tablets and small laptops */
}

/* Styles for screens larger than 1280px (typically desktops and laptops) */

@media only screen and (min-width: 1280px) {
  /* Your CSS styles for desktops and laptops */
}

/* web css start */

.title {
  color: #313b2f;
  font-family: "Playfair Display", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

/* end  */

/* mustfa css start */
.about {
  font-family: "Playfair Display";
}
.about-body {
  font-family: "Helvetica Neue";
  opacity: 60%;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
}

/* body font css  */

.css-iaplqc-MuiTypography-root {
  font-family: "Playfair Display", sans-serif !important;
}

.partners-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 47px;
  margin-left: 2rem;
}

p.partners-para {
  padding-left: 47px;
  color: #000;
  font-family: "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

img.rectangle-10 {
  /* max-width: 90% !important; */
}

.location-options {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.Content-bullets {
  padding-left: 10px !important;
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.location-sub {
  color: var(--Black, #000);
  font-family: "Playfair Display" !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  padding-bottom: 10px !important;
}

.location-head {
  color: var(--Black, #000) !important;
  font-family: "Playfair Display" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.main-menu button {
  align-items: center !important;
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-right: 0rem;
}

.css-gjpydu-MuiGrid-root {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 20px);
  margin-left: 9px;
  max-width: 1536px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: flex-start;
}

img.rectangle-8 {
  width: 100%;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.main-menu.css-gjpydu-MuiGrid-root {
  justify-content: flex-start;
  margin-left: 6px;
}

img.icon-clustor {
  padding-right: 20px;
}

ul.clustor-list li {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-bottom: 26px;
}

.box-2 {
  /* height: 150px !important; */
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start;
  margin-top: 1rem;
}

.box-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 150px !important; */
  margin-top: 1rem;
}

.about {
  color: var(--Black, #000);
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1px;
  padding-top: 16px;
}

.about-grid {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

p#aboutsection {
  color: var(--Secondary, #cbc5b3);
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.location-head {
  color: var(--Black, #000) !important;
  font-family: "Playfair Display" !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding-bottom: 10px !important;
  padding-top: 20px !important;
}

.discription-pera {
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 20px !important;
  opacity: 0.6;
  color: black;
}

.about {
  color: var(--Black, #000);
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  padding-bottom: 20px;
}

.about-sub-box {
  color: var(--Black, #000);
  font-family: "Helvetica Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.discription-pera-main {
  /*font-family: "Helvetica Neue" !important;*/
  font-size: 20px !important;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 20px !important;
  opacity: 0.6;
  /* color: black; */
  color: navajowhite;
}

.clustor-box-title {
  color: var(--White, #fff);
  font-family: "Playfair Display" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: uppercase !important;
  opacity: 0.6;
  padding-bottom: 21px !important;
  text-align: left !important;
}

.clustor-box-sub {
  color: var(--White, #fff);
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: left !important;
}

.estate {
  padding: 0px !important;
  color: black !important;
  opacity: 1 !important;
  text-align: left;
  padding-left: 50px;
  font-weight: 600 !important;
}
.aminites {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.contact-icons {
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 20px !important;
  margin-bottom: 40px !important;
}
.icon-socails {
  font-size: 30px !important;
  width: 2em !important;
}
.contact-icons span {
  position: relative;
  top: -8px;
  opacity: 0.8;
}

.contact-text-container-2 {
  padding: 30px 60px 60px 0 !important;
}

.request-btn {
  border: 1px solid #fff !important;
  background-color: transparent !important;
  color: white !important;
  padding: 16px 32px !important;
  color: var(--White, #fff);
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  z-index: 9999 !important;
}

.request-btn:hover {
  background-color: white !important;
  color: #000 !important;
}

.features-container {
  padding-right: 20px;
  padding: 0px 23px;
}

/* end  */

/* end */

/* mustfa css start here */

@media screen and (max-width: 426px) {
  /* Your CSS styles for desktops and laptops */
  .copyright {
    padding-left: 60px !important;
    padding-bottom: 10px;
  }
  .terms {
    padding-left: 80px !important;
  }
  .partners-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* .partners-des-1{
    padding-top: 160px;
  }
  .parners-logo{
   margin: 10px;
  } */
  .form-info-text {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .customGridPadding {
    flex-basis: none !important;
    max-width: 100% !important;
  }
  .contact-text-container-2 {
    display: block;
    width: 400px;
  }
}

.header-button {
  margin-left: 180px !important;
}
.header-logo {
  margin-right: 200px;
}
/* end  */

/* end */

/* contact form */
.input-field {
  width: 467px !important;
}
.customGridHeight {
  padding-top: 10px;
  padding-bottom: 10px;
  /* Adjust this value to your desired height */
}

/* input css contact css start here */

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
  border-width: 2px !important;
}

.css-14lo706 > span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  font-size: 20px !important;
  visibility: visible;
}

.contact-icons {
  display: flex;
  align-content: flex-start;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.icon-socails.css-4guzyp-MuiSvgIcon-root {
  width: 40px;
  height: 40px;
  margin-top: -11px;
}
.css-1410706 > span {
  font-size: 20px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border: 0;
}
footer {
  padding: 0px 40px;
}
.css-w2rlnz-MuiTypography-root {
  float: left;
}

.video-container {
  z-index: 9999 !important;
}
/* end */

@media (max-width: 1199px) {
  .css-fv3sos button {
    z-index: 5 !important;
  }
}

@media (min-width: 1200px) {
  .box-1 {
    border: none !important;
  }

  .box-2 {
    border: none !important;
  }

  .about {
    color: var(--Black, #000);
    font-family: "Playfair Display";
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    padding-bottom: 20px;
  }

  .about-sub-box {
    color: var(--Black, #000);
    font-family: "Helvetica Neue";
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

/* breakpoint css start here */
@media only screen and (min-width: 1280px) and (max-width: 1426px) {
  .box-1 {
    width: 150px !important;
  }

  .box-2 {
    width: 330px !important;
  }

  .main-menu button {
    align-items: center !important;
    color: var(--Black, #000);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-right: 0.6rem !important;
  }

  .box-2 {
    width: 375px;
  }
}

/* CSS for Desktop/Laptop (Larger than 1200px wide) */
@media (max-width: 1200px) {
  /* Add your CSS styles for desktop/laptop landscape here */

  .request-btn-m {
    background-color: transparent !important;
    top: 10% !important;
    right: 0px !important;
    box-shadow: none !important;
  }

  .box-clusters {
    height: 94px !important;
  }
}

@media (min-width: 1200px) and (orientation: portrait) {
  /* Add your CSS styles for desktop/laptop portrait here */

  .box-clusters {
    height: 94px !important;
  }
}

/* CSS for Tablet Landscape (Breakpoint range: 1024px to 1199px) */
@media (min-width: 1024px) and (max-width: 1199px) and (orientation: landscape) {
  .main-menu button {
    align-items: center !important;
    color: var(--Black, #000);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-right: 0rem;
  }
  /* Add your CSS styles for tablet landscape here */
}

@media (min-width: 1024px) and (max-width: 1199px) and (orientation: portrait) {
  .main-menu button {
    align-items: center !important;
    color: var(--Black, #000);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-right: 0rem;
  }

  .box-1 {
    width: 128px !important;
  }

  .box-2 {
    /* height: 150px !important; */

    width: 297px !important;
  }

  /* Add your CSS styles for tablet portrait here */
}
@media only screen and (min-width: 800px) and (max-width: 899px) {
  .about-grid-mobile {
    margin-top: 0rem !important;
  }

  .about-grid {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column !important;
    align-items: flex-start;
  }
  .box-1 {
    width: 655px !important;
  }

  .box-2 {
    width: 643px !important;
  }
  .css-mhc70k-MuiGrid-root {
    flex-direction: column !important;

    /* margin-left: -16px; */
  }

  .card-section {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .css-1osj8n2-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 80% !important;
  }

  .css-etaaxy-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
  }

  .box-clusters {
    text-align: center !important;
    border-radius: 8px !important;
    border: 1px solid rgb(203, 197, 179) !important;
    height: 100px !important;
    width: 637px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img.logo-devloper-ah {
    width: 100% !important;
    margin-top: 10rem !important;
    justify-content: center;
    flex-wrap: wrap !important;
    display: inline-flex !important;
    align-content: space-between;
    margin-left: 0rem !important;
    flex-direction: column;
  }
  .css-11lxi55-MuiGrid-root {
    -webkit-flex-basis: 41.666667%;
    -ms-flex-preferred-size: 41.666667%;
    flex-basis: 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 83.666667% !important;
  }
  .css-wn4jt8-MuiGrid-root {
    flex-direction: column !important;
  }
  .css-bwvw8j-MuiGrid-root {
    flex-basis: 100.666667% !important;

    max-width: 100.666667% !important;
  }

  .contact-row-ipad {
    flex-direction: row !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 400px) {
  .about-grid-mobile {
    margin-top: -30rem !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 791px) {
  .about-grid-mobile {
    margin-top: 0rem !important;
  }
}

/* CSS for Tablet Portrait (Breakpoint range: 768px to 1023px) */
@media (min-width: 768px) and (max-width: 768px) {
  .about-grid-mobile {
    margin-top: 0rem !important;
  }
  .about-grid {
    display: flex !important;
    justify-content: center !important;
    align-content: flex-start !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .box-2 {
    border: none !important;
    padding-left: 14px !important;
  }

  .box-1 {
    border: none !important;
  }
  .location-options {
    flex-basis: 100.333333% !important;
    max-width: 100.333333% !important;
  }

  img.rectangle-10 {
    max-width: 466px !important;
  }

  .clustor-container {
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-6.css-etaaxy-MuiGrid-root {
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
  }

  .clustor-main-div {
    display: flex !important;
    flex-direction: column !important;
  }
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.clustor-box-sub.css-1fu1a29-MuiGrid-root {
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
  }
  div#devloper {
    display: flex !important;
    flex-direction: column !important;
  }
  .css-3qfvb8-MuiGrid-root {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 85.666667% !important;
  }

  .css-bwvw8j-MuiGrid-root {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.666667%;
  }
  .box-clusters {
    text-align: center !important;
    border-radius: 8px !important;
    border: 1px solid rgb(203, 197, 179) !important;
    height: 100px !important;
    width: 582px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Add your CSS styles for tablet landscape here */
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  /* Add your CSS styles for tablet portrait here */
}

/* CSS for Mobile Landscape (Breakpoint range: 568px to 767px) */
@media (min-width: 568px) and (max-width: 783px) and (orientation: landscape) {
  .about-grid-mobile {
    margin-top: 0rem !important;
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
  }
  .about-grid {
    display: flex !important;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: flex-start;
    /* margin-left: -132px !important; */
  }

  .box-1 {
    width: 470px !important;
  }

  .box-2 {
    width: 460px !important;
  }

  .last-box {
    margin-top: 0rem !important;
  }

  /* Add your CSS styles for mobile landscape here */
}

@media (min-width: 568px) and (max-width: 767px) and (orientation: portrait) {
  .about-grid-mobile {
    margin-top: 0rem !important;
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
  }
  .about-grid {
    display: flex !important;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: flex-start;
    margin-left: -105px !important;
  }

  /* Add your CSS styles for mobile portrait here */
}

/* CSS for Mobile Portrait (Breakpoint range: 0px to 567px) */
@media (max-width: 567px) and (orientation: landscape) {
  .about-grid-mobile {
    margin-top: -9rem !important;
  }

  .box-1 {
    width: 420px !important;
  }

  .box-2 {
    /* height: 150px !important; */

    width: 408px !important;
  }

  /* Add your CSS styles for mobile landscape here */
}

@media (max-width: 567px) and (orientation: portrait) {
  /* Add your CSS styles for mobile portrait here */
}

@media only screen and (min-width: 901px) and (max-width: 1199px) {
  .logo.css-6w6kfw {
    z-index: 5 !important;
  }
  .main-menu-container {
    /* background-color: #cbc5b3; */
    /* height: 94px; */
    /* display: none !important; */
  }
  .box-1 {
    border: none !important;
    height: 46px !important;
    align-items: flex-start !important;
    /* padding: 0px !important; */
    /* width: auto !important; */
  }

  .box-2 {
    border: none !important;
    height: 46px !important;
    align-items: flex-start !important;
    padding: 0px !important;
    /* width: auto !important; */
  }

  .last-box {
    margin-top: 4rem !important;
    /* display: none; */
    height: 113px !important;
  }
  .about-grid {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column !important;
    align-items: flex-start;
  }

  .css-o4bg9w-MuiGrid-root {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100.666667% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.666667% !important;
  }
  .about-des-box {
    flex-basis: 100.666667% !important;

    max-width: 100.666667% !important;
  }

  .bg-image-col {
    flex-basis: 100.333333% !important;

    max-width: 101.333333% !important;
    padding: 534px !important;
  }

  .location-options {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 100.333333% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 101.333333% !important;
  }
  .css-etaaxy-MuiGrid-root {
    flex-basis: 100% !important;

    max-width: 104% !important;
  }

  .css-1osj8n2-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
  }

  .partners-container {
    display: block !important;
  }

  .aminities-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 8px !important;
    flex-wrap: wrap !important;
  }

  div#devloper {
    display: flex !important;
    flex-direction: column !important;
  }

  img.logo-devloper-ah {
    width: 60% !important;
    margin-top: 0rem !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    display: inline-flex !important;
    align-content: center !important;
    margin-left: 0rem !important;
  }

  .css-1qokjtz {
    font-family: sectionTitleFont;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: bold;
    z-index: 999999 !important;
  }
  .clustor-container {
    flex-direction: column !important;
  }
}

.css-1qokjtz {
  /* z-index: 999999 !important; */
}

.css-1lhfysi {
  /* z-index: 999999 !important; */
}

.css-fv3sos button {
  /* z-index: 999999 !important; */
}

@media (max-width: 1200px) {
  .box-1 {
    border: none !important;
    height: 60px !important;
  }

  .box-2 {
    border: none !important;
    height: 60px !important;
  }
  .css-etaaxy-MuiGrid-root {
    max-width: 100%;
    padding-right: 0px !important;
  }

  /* .css-1osj8n2-MuiGrid-root {
    flex-basis: 90% !important;

    max-width: 90% !important;
  }

  .box-clusters {
    text-align: center !important;
    border-radius: 8px !important;
    border: 1px solid rgb(203, 197, 179) !important;
    height: 100px !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  } */
  p.MuiTypography-root.MuiTypography-body1.location-sub.css-naxtqj-MuiTypography-root {
    padding-left: 13px;
  }

  .bg-image-col {
    max-width: 106.333333% !important;
    padding-top: 496px !important;
    margin: auto !important;
    width: 100% !important;
    /* height: 100% !important; */
    margin-top: 2rem !important;
    margin-left: 1rem !important;
  }

  img.logo-devloper-ah {
    align-items: center !important;
  }
}

.request-btn {
  display: none !important;
}

/* menu css start here */

.request-btn-m {
  background-color: transparent !important;
}
.menu-box-right-col {
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 330px;
  z-index: 100049;
}

li.nav-link-btn {
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  list-style: none;
  text-decoration: none;
}

.nav-link-btn a {
  color: black !important;
  text-decoration: none !important;
}

.ul.menu-container {
  text-decoration: none !important;
}

.inner-header {
}
.header-content {
}

ul.menu-header {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 20px;
  color: var(--Black, #000);
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.request-btn-m {
  z-index: 9999px !important;
}

div#main_menu_container-inner-header {
  position: fixed;
  z-index: 40;
  top: 0;
}

.request-btn-m {
  background-color: transparent !important;
  z-index: 8;
}

.contact-btn {
}

ul.contact-menu {
  padding: 12px;
}

li.contact-list {
  border: 1px solid var(--Secondary, #cbc5b3);
  padding: 20px 10px;
  list-style: none;
  text-decoration: none;
}

li.contact-list {
  border: 1px solid var(--Secondary, #cbc5b3);
  padding: 20px 10px;
  list-style: none;
  text-decoration: none;
  color: var(--Primary, #313b2f);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}

li.contact-list a {
  text-decoration: none;
  color: var(--Primary, #313b2f);
}

.contact-menu {
  width: 96%;
  position: absolute;
  bottom: 0rem;
}
.nav-link-btn {
  padding: 20px 32px;
  /* margin-bottom: 1rem; */
}

li.nav-link-btn:hover {
  background-color: #cbc5b3;
}

li.nav-link-btn {
  width: 85%;
  padding-left: 10px;
  margin-top: 1rem;
}

ul.menu-container {
  padding: 0px 20px;
}
.contact-list:hover {
  background-color: #cbc5b3;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 3.4s !important;
}

/* .header-content {
  position: relative;
  top: 48px;
} */

img.logo.css-yb1sgc {
  width: 100px !important;
  height: auto !important;
  margin-top: 70px;
}

.request-btn-m {
  margin-top: 35px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 330px;
  z-index: 100049;
}

/* menu arrow css start here */

img.menu-arrow {
  float: inline-end;
  border: 1px solid;
  margin-top: 0px;
  margin-right: -22px;
  padding: 5px 2px;
  width: 20px;
}

/* padding all section css start */

@media only screen and (min-width: 1500px) and (max-width: 1920px) {
  .partners-container .partners-des {
    padding-left: 20px !important;
  }
  .Feature-container-main {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .experience-container-main {
    flex-basis: 100.666667% !important;

    max-width: 100.666667% !important;
  }

  .features-container {
    width: 45vw !important;
  }
  .amenities-main-section {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .request-btn-m {
    background-color: transparent !important;
    top: 14% !important;
    right: 65px !important;
    box-shadow: none !important;
  }

  .features-container {
    width: 84vw !important;
  }
  .about-container {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .location-main-section {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .clustor-container {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .devloper-container {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .partners-container-main {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .contact-container-main-section {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .about-sub-box {
    color: var(--Black, #000);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .partners-container .partners-des {
    padding-left: 20px !important;
  }
  .request-btn-m {
    background-color: transparent !important;
    top: 11% !important;
    right: 5% !important;
    box-shadow: none !important;
  }

  .footer-menu li a {
    font-size: 16px !important;
  }

  .social-links-item a {
    font-size: 16px !important;

    text-decoration: none !important;
  }

  .logo.css-6w6kfw {
    top: 10% !important;
    left: 10% !important;
  }
  .about-container {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .location-main-section {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .clustor-container {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .devloper-container {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .partners-container-main {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .contact-container-main-section {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 500px) {
  .about-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-xl-5.css-1q9c3ht-MuiGrid-root {
    padding-left: 0px !important;
  }

  .about-grid-mobile-boxes {
    padding-left: 0px !important;
  }

  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
  }
  .bg-image-col {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .clustor-main-div {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .location-main-section {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .clustor-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .devloper-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .partners-container-main {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .contact-container-main-section {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .clustor-boxes-section-main {
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .experience-container-main {
    flex-basis: 100.666667% !important;

    max-width: 90.666667% !important;
  }

  .boxs-border-cards {
    gap: 67px !important;
  }

  .discription-pera {
    font-size: 16px !important;
  }
  .discription-pera-main {
    font-size: 16px !important;
  }

  .about {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
  }

  .icon-list-title {
    font-size: 16px !important;
  }

  .icon-list-heading {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  ul.footer-menu {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem !important;
    padding: 0px;
    list-style: none;
  }
}
a.text-black-nav {
  text-decoration: none;
}
/* end */

/* end */

/* end css */
