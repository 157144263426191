.partners-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 47px;
}
.partners-des {
  opacity: 1 !important;
}

h2.titlepartners {
  color: #000;
  font-family: "Playfair Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

img.partners-logo {
  width: 150px;
}

.parners-logo-card {
  flex-basis: 13.333333% !important;
  display: flex;
  justify-content: flex-start;
  gap: 0;
}

p.partners-para {
  padding-left: 47px;
}
