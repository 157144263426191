img.logo-devloper-ah {
  width: 58%;
  margin-top: 4rem;
  justify-content: flex-end;
  flex-wrap: wrap;
  display: inline-flex;
  align-content: center;
  margin-left: 7rem;
}

h2 {
  font-family: "Playfair Display" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

p {
  font-family: "Helvetica Neue", sans-serif;

  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
