.about {
  font-family: "Playfair Display" !important;
}
.about-body {
  font-family: "Playfair Display" !important;
}
.about-body {
  font-family: "Helvetica Neue" !important;
  opacity: 60% !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  padding-bottom: 20px !important;
  line-height: 29px !important;
}

.location-head {
  color: var(--Black, #000) !important;
  font-family: "Playfair Display" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.location-sub {
  color: var(--Black, #000) !important;
  font-family: "Playfair Display" !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding-bottom: 20px;
  padding-top: 10px;
}

.img-col {
  background-repeat: no-repeat;
  background-size: cover;
}

.about-des-box {
  padding-right: 30px !important;
}

.bg-image-col {
  padding-left: 30px !important;
}

.box-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  /* height: 113px !important; */
  margin-top: 1rem;
  width: 184px;
  text-align: left !important;
}

.box-2 {
  /* height: 150px !important; */
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  margin-top: 1rem;
  /* height: 113px !important; */
  margin-top: 1rem;
  width: 401px;
  text-align: left !important;
  /* padding-left: 28px !important; */
}

.about-container {
  margin-top: 0rem !important;
  margin-bottom: 4rem !important;
}

/* .bg-image-col {
  margin-left: 1rem !important;
} */

.boxs-border-cards {
  display: flex;
}

.boxs-border-cards {
  display: flex;
  justify-content: flex-start;
  gap: 121px;
  margin-bottom: 12px;
  border-radius: 10px;
  border: 1px solid var(--Primary, #313b2f);
  padding-left: 20px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 44px;
}

@media only screen and (max-width: 390px) {
  /* .about-grid-mobile-boxes {
    margin-top: -32rem !important;
  } */
}
@media only screen and (min-width: 768px) and (max-width: 768px) {
  .boxs-border-cards {
    display: flex;
    justify-content: flex-start;
    gap: 67px;
    margin-bottom: 12px;
    border-radius: 10px;
    border: 1px solid var(--Primary, #313b2f);
    padding-left: 20px;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 44px;
    width: 678px !important;
  }
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
  .boxs-border-cards {
    display: flex;
    justify-content: flex-start;
    gap: 67px;
    margin-bottom: 12px;
    border-radius: 10px;
    border: 1px solid var(--Primary, #313b2f);
    padding-left: 20px;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 44px;
    width: 104% !important;
    flex-direction: column;
  }
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-xl-5.css-1q9c3ht-MuiGrid-root {
    max-height: 550px;
  }

  .about-des-box {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100.666667% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.666667% !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1199px) {
  .about-des-box {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100.666667% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.666667% !important;
  }

  .boxs-border-cards {
    display: flex;
    justify-content: flex-start;
    gap: 67px;
    margin-bottom: 12px;
    border-radius: 10px;
    border: 1px solid var(--Primary, #313b2f);
    padding-left: 20px;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 44px;
    width: 100%;
  }
  .css-bwvw8j-MuiGrid-root {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100.666667% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100.666667% !important;
  }
}
@media only screen and (max-width: 500px) {
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-xl-5.css-1q9c3ht-MuiGrid-root {
    max-height: 550px;
  }

  /* .about-grid-mobile-boxes {
    margin-top: -19rem !important;
  } */
  .boxs-border-cards {
    display: flex;
  }
  .boxs-border-cards {
    display: flex;
    gap: 44px;
    flex-direction: column;
    width: 312px;
    align-items: flex-start;
    padding-left: 29px;
    max-width: 100%;
  }
}

.about {
  font-family: "Playfair Display" !important;
  text-transform: uppercase;
  font-size: 16px !important;
  width: 80%;
}

.bg-image-col:hover,
.bg-image-col:focus {
  transform: scale(1.2);
}
